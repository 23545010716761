import "./App.css";
import React from "react";
import {
  CCard,
  CCardImage,
  CCardBody,
  CCardTitle,
  CCardText,
  CButton,
  CNav,
  CNavItem,
  CNavLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

import CarouselContainer from './components/slider';
import "bootstrap/dist/css/bootstrap.min.css";
import data from "./Furniture.json";
import { useState } from "react";
import filter1 from "./components/common/images/fountain.png";
import filter2 from "./components/common/images/doublebed.png";
import filter3 from "./components/common/images/diningtable.png";
 import filter4 from "./components/common/images/table.png";

 import filter5 from "./components/common/images/wallclock.png";
 import filter6 from "./components/common/images/flowerpot.png";
 import filter7 from "./components/common/images/chair.png";
 import filter8 from "./components/common/images/marble.png";
 import filter9 from "./components/common/images/temple.png";
 import filter10 from "./components/common/images/all.png";



function Furnitur() {
  const [items, setItems] = useState(data);

  const [activeKey, setActiveKey] = useState(0);

  const filterCat = (catItem) => {
    const catItems = data.filter((category) => {
      return category.category === catItem;
    });
    setItems(catItems);
  };

  const filterSubCat = (catItem) => {
    const catItems = data.filter((category) => {
      return category.sub_category === catItem;
    });
    setItems(catItems);
  };
  return (
    <div>
      <CarouselContainer />
      <div classname="nav-super-parent">
      <CNav className="nav-parent"
        variant="pills"
        layout="justify"
        role="tablist"

      >
        {/* <CNavItem>
      <CNavLink active={activeKey === 1} className="dropdown">
        <div className="dropdown">
          <div onClick={() => filterCat("HOME DECOR") & setActiveKey(1)}>
            Home Furnitures
          </div>
          <div className="dropdown-content">
            <CNavLink
              onClick={() => filterSubCat("DOUBLE BED") & setActiveKey(1)}
              active={activeKey === 2}
            >
              Double Bed
            </CNavLink>
            <CNavLink
              onClick={() => filterSubCat("CHAIR") & setActiveKey(1)}
              active={activeKey === 2}
            >
              Chair
            </CNavLink>
            <CNavLink
              onClick={() => filterSubCat("DINING TABLE") & setActiveKey(1)}
              active={activeKey === 2}
            >
              Dining Table
            </CNavLink>
          </div>
        </div>
      </CNavLink>
    </CNavItem> */}

        <CNavLink className="nav-prod"
          onClick={() => filterSubCat("DOUBLE BED") & setActiveKey(6)}
          active={activeKey === 6}
        >
          <img className="products" src={filter2} height="50" width="50" alt="Logo" /><br />
          Double Bed<br />
        </CNavLink>
        <CNavLink className="nav-prod"
          onClick={() => filterSubCat("CHAIR") & setActiveKey(7)}
          active={activeKey === 7}
        >
          <img className="products" src={filter7
          } height="50" width="50" /> <br/>

          Chair
        </CNavLink>
        <CNavLink className="nav-prod"
          onClick={() => filterSubCat("DINING TABLE") & setActiveKey(8)}
          active={activeKey === 8}
        >
          <img className="products" src={filter3} height="50" width="50" alt="Logo" /><br />

          Dining Table
        </CNavLink>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("WORK FROM HOME") & setActiveKey(9)}
            active={activeKey === 9}
          >
            <img className="products" src={filter4} height="50" width="50" alt="Logo" /><br />

            TABLE
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("clock") & setActiveKey(5)}
            active={activeKey === 5}
          >
            <img className="products" src={filter5} height="50" width="50" /> <br />
            Wall Clock
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("vase") & setActiveKey(10)}
            active={activeKey === 10}
          >
            <img className="products" src={filter6} height="50" width="50" /> <br />
            Flower Pot
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("marble") & setActiveKey(11)}
            active={activeKey === 11}
          >
            <img className="products" src={filter8} height="50" width="50" /> <br />
            Marble
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("fountain") & setActiveKey(12)}
            active={activeKey === 12}
          >
            <img className="products" src={filter1}
             height="50" width="50" /> <br />
            Fountain
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => filterCat("temple") & setActiveKey(13)}
            active={activeKey === 13}
          >
            <img className="products" src={filter9} height="50" width="50" /> <br />
            Temple
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink className="nav-prod"
            onClick={() => setItems(data) & setActiveKey(0)}
            active={activeKey === 0}
          >
             <img className="products" src={filter10} height="50" width="50" />
            <br />
            All
          </CNavLink>
        </CNavItem>
        {/* <CNavItem>
          <CNavLink href="#" disabled>

          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#" disabled>

          </CNavLink>
        </CNavItem>
        <CNavItem className="switch mode button">
          <CNavLink

          >


        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="#" disabled>

        </CNavLink>
      </CNavItem>

      <CNavItem>
        <CNavLink href="#" disabled>

        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="#" disabled>

        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="#" disabled>

        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="#" disabled>


        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="#">
          Quotations
        </CNavLink>
      </CNavItem>
      <CNavItem>
        <CNavLink href="mailto:guest@gmail.com" >
          Email
        </CNavLink>
      </CNavItem>

      <CNavItem>
        <CNavLink href="tel:360-325-7623">Call Now <br />+1.00000000000.
        </CNavLink>
      </CNavItem> */}


    </CNav>
    </div>

    <div className="table" style={{ width: "100%", padding: "20px" }}>
        <div class="row">
          {items.map((elem) => {
            const { id, name, imgSrc, WoodType, Dimension, price, size } = elem;
            return (
              <>
                {/* <span id="card"> */}


                <CCard className="ab"
                  key={id}
                  style={{ width: "18rem", marginBottom: "10px" , itemAlign: "center" }}
                >
                  <CCardText className="card-da-mukabla">ID: {id}</CCardText>
                  <CCardImage className="card-da-mukabla-da-image"
                    orientation="top"
                    src={"https://storage.googleapis.com/furnitureezy-58e9d.appspot.com/"+id+"/"+id+".PNG?authuser=1"}
                    width={200}
                    height={200}
                    style={{ padding: "20px" }} />
                  <CCardBody>

                    <CCardTitle className="card-da-mukabla-da-title">{name} {WoodType}</CCardTitle>
                    <CCardText className="EXCUSES">{size} </CCardText>

                  </CCardBody>
                </CCard>

                {/* </span> */}

              </>

            );
          })}
        </div>
      </div>

    </div>
  );
}
export default Furnitur;
