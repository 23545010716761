import React from 'react'
const Services = () => {
  return (
    <div className="container">
    
        <h1 className="text-center" style={{paddingTop: "30%"}}>
          Services
        </h1>
        
    </div>
  )
}
export default Services;