import React from 'react'
import './App.css';
import Contact from "./components/Contact";
class jaitupyarelal extends React.Component {
    render () {
        return(
            // <div className="haren"><br />
            //    <center><br />
            //        <div id="pasti"><h3>REGISTRATION LOGIN</h3>
            //   <h5> <div id="pastis">STEP 1 for REGISTRATION </div></h5></div><br />
            //    <h6 id="pastis1">Phone number verification </h6>


    <div className="app">
      <Contact />
    </div>
  

                    
                
            //    </center>
            // </div>
            )
    }
}
export default jaitupyarelal