import React , {useEffect} from 'react'
import './navbar.css';

import { NavLink } from 'react-router-dom';
import {Link} from 'react-router-dom';
// import { Route, Routes, BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';
import $ from 'jquery';
import furnlogo from './images/furnlogo.png';
import jaitupyarelal from '../../../connectapp';
// import firebase from 'firebase';
const Navbar = () => {

  function animation(){
    var tabsNewAnim = $('#navbarSupportedContent');
    var activeItemNewAnim = tabsNewAnim.find('.active');
    var activeWidthNewAnimHeight = activeItemNewAnim.innerHeight();
    var activeWidthNewAnimWidth = activeItemNewAnim.innerWidth();
    var itemPosNewAnimTop = activeItemNewAnim.position();
    var itemPosNewAnimLeft = activeItemNewAnim.position();
    $(".hori-selector").css({
      "top":itemPosNewAnimTop.top + "px",
      "left":itemPosNewAnimLeft.left + "px",
      "height": activeWidthNewAnimHeight + "px",
      "width": activeWidthNewAnimWidth + "px"
    });
    $("#navbarSupportedContent").on("click","li",function(e){
      $('#navbarSupportedContent ul li').removeClass("active");
      $(this).addClass('active');
      var activeWidthNewAnimHeight = $(this).innerHeight();
      var activeWidthNewAnimWidth = $(this).innerWidth();
      var itemPosNewAnimTop = $(this).position();
      var itemPosNewAnimLeft = $(this).position();
      $(".hori-selector").css({
        "top":itemPosNewAnimTop.top + "px",
        "left":itemPosNewAnimLeft.left + "px",
        "height": activeWidthNewAnimHeight + "px",
        "width": activeWidthNewAnimWidth + "px"
      });
    });
  }

  useEffect(() => {

    animation();
    $(window).on('resize', function(){
      setTimeout(function(){ animation(); }, 500);
    });

  }, []);

  return (
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">
		<div class="container">
			<a class="navbar-brand" href="/"><img src={furnlogo}/></a> <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler" data-target="#navbarSupportedContent" data-toggle="collapse" type="button"><span class="navbar-toggler-icon"></span></button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item active">
						<a class="nav-link" href="/"><br />Home</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="/connectapp"><br/>Quotation</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="mailto:orders@sabekuch.com">Email <br />orders@sabekuch.com</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="tel:+1 360-325-7623">Call-NOW <br />+1 360-325-7623</a>
					</li>
					{/* <!-- <li class="nav-item">
						<a class="nav-link" href="#">Contact</a>
					</li> --> */}
				</ul>
			</div>
		</div>
	</nav>

  )
}
export default Navbar;
