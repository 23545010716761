import React from 'react';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import sl1 from "./common/images/slider1.jpeg";
import sl2 from "./common/images/slider2.png";
import sl3 from "./common/images/slider3.png";
import sl4 from "./common/images/slider4.png";
import sl5 from "./common/images/slider5.png";
import sl6 from "./common/images/slider6.png";
// import React from 'react'
import { Carousel } from 'react-bootstrap';
import  "./common/navbar/navbar.css";

// import image1 from './../assets/images/1.jpg';
// import image2 from './../assets/images/2.jpg';
// import image3 from './../assets/images/3.jpg';

// function ImageSlider() {
//     let settings = {
//         dot: true,
//         infinite: true,
//         speed:500,
//         slidestoShow: true,
//         slidestoScroll: true,
//         cssEase:"linear"
//     };
//         return(
//             <Slider {...settings}>
//                 <div className="card-wrapper">
//                     <div className="card">
//                         <div className="card-image">
//                             <img src="https://media.eastwestfurniture.net/2022/01/aa2c4d23-v027ab264-6-scaled.jpg" height="300" width="300"/>
//                             </div>
//                             <ul class="social-items">
//                                 <li><a href="#"><i className="fa fa-facebook"></i></a></li>
//                                 <li><a href="#"><i className="fa fa-email"></i></a></li>
//                                 <li><a href="#"><i className="fa fa-call"></i></a></li>
//                                 <li><a href="#"><i className="fa fa-instagram"></i></a></li>
//                             </ul>
//                             <div className="details">
//                                 <h2>Double Bed-FURNITURES<span class="job-title">EZYfurniture</span></h2>
//                                 </div>
//                         </div>
//                     </div>

//             </Slider>
//         )
        
// }


const CarouselContainer = () => {
  return (
    <Carousel fade={true} pause={false}>
      <Carousel.Item interval={2000}>
      
        <img
          className="d-block w-100 h-5"

          style={{
            marginTop: "1%",
            fontWeight: "bolder"
          }
              
          }
          src={sl3}
          alt="First slide"
          // height="350 px"

        />
       
        <Carousel.Caption>
          <h3></h3>
          <div className='jaimatadi'>
          <p>Decor the dreams with wood beauty</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-5"
          src={sl4}
          alt="Third slide"
          style={{
            marginTop: "1%"
          }
              
          }
          // height="350 px"

        />
        <Carousel.Caption>
          <h3></h3><div className='jaimatadi'>
          <p>Decorate your Life with Arts</p> </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-5"
          src={sl2}
          alt="Third slide"
          // height="350px"

          style={{
            marginTop: "1%"
          }
              
          }
        />
        <Carousel.Caption>
          <h3></h3><div className='jaimatadi'>
          <p>The furniture that defines you</p> </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-5"
          src={sl1}
          alt="Third slide"
          // height="350px"

          style={{
            marginTop: "1%"
          }
              
          }
        />
        <Carousel.Caption>
          <h3></h3> <div className='jaimatadi'>
          <p>Crafted with excellent Wood</p></div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-5"
          src={sl5}
          alt="Third slide"
          // height="350px"

          style={{
            marginTop: "1%"
          }
              
          }
        />
        <Carousel.Caption>
          <h3></h3><div className='jaimatadi'>
          <p>For your dream home</p> </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 h-5"
          src={sl6}
          alt="Third slide"
          // height="350px"

          style={{
            marginTop: "1%"
          }
              
          }
        />
        <Carousel.Caption>
          <h3></h3> <div className='jaimatadi'>
          <p>A commitment of years</p></div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel> 
  )
}

export default CarouselContainer;
// export default ImageSlider;